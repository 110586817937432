import React from "react";
import {
  AdvancedMarker,
  AdvancedMarkerProps,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import Image from "next/image";
import { MapLocationMarker } from "@/src/component/partial/Map/Map";
import { ASSET_URL, KEYS } from "@/src/const";
import { SharedConfigManager } from "@/src/util";

const AdvancedMarkerWithRef = (
  props: AdvancedMarkerProps & {
    onMarkerClick: (marker: google.maps.marker.AdvancedMarkerElement) => void;
  },
) => {
  const { children, onMarkerClick, ...advancedMarkerProps } = props;
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <AdvancedMarker
      onClick={() => {
        if (marker) {
          onMarkerClick(marker);
        }
      }}
      ref={markerRef}
      {...advancedMarkerProps}
    >
      {children}
    </AdvancedMarker>
  );
};

interface MapMarkerProps {
  marker: MapLocationMarker;
  onClick: (
    marker: MapLocationMarker,
    markerElement: google.maps.marker.AdvancedMarkerElement,
  ) => void;
  onMouseEnter: (markerId?: string) => void;
  onMouseLeave: () => void;
  isHovered: boolean;
  size?: number;
  isOldVersion?: boolean;
}

const DEFAULT_SIZE = 20;
const DEFAULT_HEIGHT_DIFFERENCE = 10;

function MapMarker({
  marker,
  onClick,
  onMouseEnter,
  onMouseLeave,
  isHovered,
  size = DEFAULT_SIZE,
  isOldVersion = false,
}: MapMarkerProps) {
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);

  return (
    <AdvancedMarkerWithRef
      position={marker}
      aria-label="View Venue"
      onMarkerClick={(markerElement: google.maps.marker.AdvancedMarkerElement) =>
        onClick(marker, markerElement)
      }
      onMouseEnter={() => onMouseEnter(marker.listingData?.slug)}
      onMouseLeave={onMouseLeave}
      style={{
        transform: `scale(${isHovered ? 1.3 : 1})`,
        transition: "transform 0.2s",
      }}
    >
      {/*TODO find out why Image for old pin is not showing on prod */}
      {isOldVersion ? (
        <Image
          src={`${ASSET_URL}/blacePinLogo.png?auto=format&w=40&q=100`}
          alt="location marker"
          data-testid="map-marker-old"
          height={size + DEFAULT_HEIGHT_DIFFERENCE}
          width={size}
        />
      ) : (
        <Image
          src={`${SVG_URL}/${isHovered ? "locationPinSelectedIcon" : "locationPinIcon"}.svg`}
          height={size + DEFAULT_HEIGHT_DIFFERENCE}
          width={size}
          alt="map marker"
          data-testid="map-marker-new"
        />
      )}
    </AdvancedMarkerWithRef>
  );
}

export default MapMarker;
